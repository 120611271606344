var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTPPromise } from 'base-api/APIBase';
class BrregAPIImpl {
    constructor() {
        this.brRegAPI = 'https://data.brreg.no/enhetsregisteret/api/enheter';
    }
    searchEnhetsregisteret(navn) {
        return HTTPPromise.newWithSignal((signal) => __awaiter(this, void 0, void 0, function* () {
            const url = this.brRegAPI + '?navn=' + encodeURIComponent(navn);
            const requestToBrReg = yield fetch(url, {
                signal,
            });
            const dataBackFromBrReg = yield requestToBrReg.json();
            if ('_embedded' in dataBackFromBrReg) {
                return dataBackFromBrReg._embedded.enheter;
            }
            return [];
        }));
    }
}
export const BrregAPI = new BrregAPIImpl();
