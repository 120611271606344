/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { ewSystemErrorRestHandler_Response } from '../../../schemas/ewSystemErrorRestHandler_Response';
export class SystemErrorAPI extends APIRestClient {
    /**
     * Sende inn feilrapport
     * Implementert hovedsakelig i *ewSystemErrorRestHandler*::*create*
     */
    static postSystemError(__DATA) {
        return this._post('/system/error', {}, null, JSON.stringify(__DATA), ewSystemErrorRestHandler_Response.classOf(ewSystemErrorRestHandler_Response, new ewSystemErrorRestHandler_Response()), [], 'json');
    }
}
