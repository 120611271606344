var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
let SignupSubmitSuccess = class SignupSubmitSuccess extends LitElement {
    static get is() {
        return 'signup-submit-success';
    }
    /** @override */ render() {
        return html `
            <div class="flex justify-center max-w-2xl ml-auto mr-auto">
                <div class="pb-8 pl-8 pr-8 w-full lg:w-3/5">
                    <div class="flex flex-col justify-center bg-white p-6 pt-2 rounded shadow">
                        <div>
                            <img
                                class="mx-auto block items-center"
                                src="images/checkmark.png"
                                width="128"
                                alt="Checkmark"
                            />
                        </div>
                        <div class="text-center">Registrering OK</div>
                        <div class="mx-auto mt-4">
                            Du vil straks få tilsendt e-post med din innloggingsinformasjon.
                        </div>
                        <div class="text-center">Velkommen!</div>
                        <div class="mx-auto mt-6">
                            <a
                                class="bg-cerum-orange-darkest hover:bg-cerum-orange-dark text-white md:mt-0 mt-4 mx-auto sm:mx-2 max-w-xs w-48 rounded-full text-center leading-none font-semibold block py-3 no-underline px-8 text-xl"
                                href="https://cerum.no/x/beta/"
                                >Logg inn</a
                            >
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
    createRenderRoot() {
        return this;
    }
};
SignupSubmitSuccess._deps = [];
SignupSubmitSuccess = __decorate([
    customElement(SignupSubmitSuccess.is)
], SignupSubmitSuccess);
export { SignupSubmitSuccess };
