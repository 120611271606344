import { Router } from '@vaadin/router';
import { SignupPageForm } from '../components/SignupPageForm';
import { SignupSubmitSuccess } from '../pages/SignupSubmitSuccess';
import { SignupSubmitError } from '../pages/SignupSubmitError';
export const router = new Router();
router.setRoutes([
    {
        path: '/',
        component: SignupPageForm.is,
    },
    {
        path: '/success',
        component: SignupSubmitSuccess.is,
    },
    {
        path: '/error',
        component: SignupSubmitError.is,
    },
]);
