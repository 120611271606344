var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { html, LitElement } from 'lit';
import { customElement } from 'lit/decorators.js';
let SignupSubmitError = class SignupSubmitError extends LitElement {
    static get is() {
        return 'signup-submit-error';
    }
    /** @override */ render() {
        return html `
            <div class="flex justify-center max-w-2xl ml-auto mr-auto">
                <div class="pb-8 pl-8 pr-8 w-full lg:w-3/5">
                    <div class="flex flex-col justify-center bg-white p-6 pt-2 rounded shadow">
                        <div>
                            <img
                                class="mx-auto block items-center"
                                src="images/error.png"
                                width="128"
                                alt="Error"
                            />
                        </div>
                        <div class="text-center">
                            Noe gikk galt. Din feil er logget. Vennligst forsøk igjen senere.
                        </div>
                        <div class="mt-8 text-center text-cerum-grey-lightest">
                            Error ID: ${this.getErrorId()}
                        </div>
                    </div>
                </div>
            </div>
        `;
    }
    getErrorId() {
        const params = new URLSearchParams(location.search);
        return params.get('id');
    }
    createRenderRoot() {
        return this;
    }
};
SignupSubmitError._deps = [];
SignupSubmitError = __decorate([
    customElement(SignupSubmitError.is)
], SignupSubmitError);
export { SignupSubmitError };
