var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { html, LitElement } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { BrregAPI } from 'standalone-common/apis/BrregAPI';
import { ewRestSignupRequest } from 'standalone-api/schemas/ewRestSignupRequest';
import { SelfservicesignupAPI } from 'standalone-api/cerum/signup/selfservicesignup/SelfservicesignupAPI';
import { isErrorHTTP, isSuccessHTTP } from 'base-api/APIBase';
import { StandaloneAjax } from 'standalone-api/StandaloneAjax';
import { Router } from '@vaadin/router';
import { SystemErrorAPI } from 'standalone-api/cerum/standalone/system_error/SystemErrorAPI';
import { Checkbox } from '@vaadin/checkbox';
import { EmailField } from '@vaadin/email-field';
import { ListBox } from '@vaadin/list-box';
import { NumberField } from '@vaadin/number-field';
import { TextField } from '@vaadin/text-field';
import { Item } from '@vaadin/item';
let SignupPageForm = class SignupPageForm extends LitElement {
    static get is() {
        return 'signup-page-form';
    }
    constructor() {
        super();
        this.invoiceSameAsAddress = true;
        this.invoiceAddress = '';
        this.invoicePostalCode = '';
        this.invoicePostalAddress = '';
        this.invoiceEmail = '';
        this.signupFormData = new ewRestSignupRequest();
        this.signupSignupSearchResult = [];
        this.emailExists = false;
        this.typingTimer = 0;
        this.updateComplete.then(() => {
            StandaloneAjax.registerScopeInterceptor(['https://api.cerum.no/cerum/signup'], (_url, _xhr, _scopes) => __awaiter(this, void 0, void 0, function* () {
                return 'mytninjatoken';
                // Den her må vi snakk om BT.
            }));
        });
    }
    signupGetCompany(signupCompanySearchField) {
        return __awaiter(this, void 0, void 0, function* () {
            const signupCompanySearch = signupCompanySearchField.value;
            this.abortSearchRequest();
            this.pending_request = BrregAPI.searchEnhetsregisteret(signupCompanySearch);
            this.signupSignupSearchResult = yield this.pending_request;
            this.pending_request = undefined;
        });
    }
    getValidInputFieldFromEvent(event) {
        const userInputInSearchField = event.target;
        if (!userInputInSearchField || !userInputInSearchField.value) {
            return null;
        }
        return userInputInSearchField;
    }
    userInputTimer(event) {
        const userInputInSearchField = this.getValidInputFieldFromEvent(event);
        if (!userInputInSearchField) {
            return;
        }
        if (this.typingTimer) {
            clearTimeout(this.typingTimer);
        }
        const match = this.matchOrgnr(userInputInSearchField.value);
        if (match) {
            const hit = this.signupSignupSearchResult
                .filter((brRegResult) => brRegResult.organisasjonsnummer === match.orgnr)
                .shift();
            if (hit) {
                this.abortSearchRequest();
                userInputInSearchField.value = match.name;
                this.loadValuesFromResult(hit);
                return;
            }
        }
        const doneTypingInterval = 500;
        this.typingTimer = window.setTimeout(() => {
            this.signupGetCompany(userInputInSearchField);
        }, doneTypingInterval);
    }
    abortSearchRequest() {
        if (this.pending_request) {
            this.pending_request.abort();
        }
    }
    matchOrgnr(value) {
        const matchFromBrRegResult = value.match(/(.*) \((.*)+(\d{9})\)$/);
        if (!matchFromBrRegResult) {
            return null;
        }
        return {
            orgnr: matchFromBrRegResult[3],
            name: matchFromBrRegResult[1],
        };
    }
    /** @override */ updated(changedProperties) {
        changedProperties.forEach((_oldValue, propertyName) => {
            switch (propertyName) {
                case 'signupFormData':
                    this._signupFormDataObserver();
                    break;
            }
        });
        super.updated(changedProperties);
    }
    _signupFormDataObserver() {
        console.log(this.signupFormData);
    }
    loadValuesFromResult(brreg_lookup) {
        this.signupFormData.orgName = brreg_lookup.navn;
        this.signupFormData.orgNo = brreg_lookup.organisasjonsnummer;
        this.signupFormData.postalNo = brreg_lookup.forretningsadresse.postnummer;
        this.signupFormData.postalAddress = brreg_lookup.forretningsadresse.poststed;
        this.signupFormData.streetAddress = brreg_lookup.forretningsadresse.adresse.join(', ');
        this.requestUpdate('signupFormData');
    }
    get validationFields() {
        return document.querySelectorAll('.validate');
    }
    _validateFields() {
        if (this.invoiceSameAsAddress) {
            this.signupFormData.invoiceAddress = this.signupFormData.streetAddress;
            this.signupFormData.invoicePostalCode = this.signupFormData.postalNo;
            this.signupFormData.invoicePostalAddress = this.signupFormData.postalAddress;
        }
        else {
            this.signupFormData.invoiceAddress = this.invoiceAddress;
            this.signupFormData.invoicePostalCode = this.invoicePostalCode;
            this.signupFormData.invoicePostalAddress = this.invoicePostalAddress;
        }
        if (!this.signupFormData.ehfInvoice) {
            this.signupFormData.invoiceEmail = this.invoiceEmail;
        }
        let hasInvalidFields = false;
        this.validationFields.forEach((validationField) => {
            if (!validationField.validate()) {
                hasInvalidFields = true;
            }
        });
        return !hasInvalidFields;
    }
    /** @override */ render() {
        return html ` <style>
                label,
                input,
                select {
                    font-family: -apple-system, system-ui, Roboto, 'Segoe UI', Helvetica, Arial, sans-serif,
                        'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
                    color: rgba(27, 43, 65, 0.72);
                    line-height: 21px;
                }
                input,
                select {
                    height: 36px;
                    background-color: #e2e6eb;
                }
                input:hover,
                select:hover {
                    background-color: #d5dae1;
                }
                label {
                    padding-bottom: 0.5em;
                    line-height: 1;
                    font-weight: 500;
                }
                vaadin-text-field,
                vaadin-number-field,
                vaadin-email-field,
                vaadin-select {
                    width: 100%;
                }
            </style>
            <div class="flex justify-center max-w-2xl ml-auto mr-auto">
                <div class="pt-8 pl-8 pr-8 w-full lg:w-3/5">
                    <p class="text-xl py-2 text-cerum-grey-darkest leading-tight mb-8">
                        Du har nå valgt Cerum Innsjekk. Pris kr. 89 pr. mnd.
                    </p>
                </div>
            </div>
            <div class="flex justify-center max-w-2xl ml-auto mr-auto">
                <div class="pb-8 pl-8 pr-8 w-full lg:w-3/5">
                    <div class="flex justify-center bg-white p-6 pt-2 rounded shadow">
                        <div class="w-full">
                            <div class="flex flex-col lg:flex-row">
                                <div class="w-full lg:w-3/4">
                                    <!-- <label class="block text-sm"> Firmanavn </label> -->
                                    <vaadin-text-field
                                        @input=${this.userInputTimer}
                                        class="validate block appearance-none w-full px-2 rounded"
                                        label="Firmanavn"
                                        autocomplete="organization"
                                        placeholder="Søk her"
                                        value="${this.signupFormData.orgName}"
                                        @value-changed="${(event) => {
            this.signupFormData.orgName = event.target.value;
            this.requestUpdate('signupFormData');
        }}"
                                    ></vaadin-text-field>
                                    <vaadin-list-box
                                        id="searchResults"
                                        @selected-changed="${(event) => {
            console.log(event.detail.value);
            if (event.detail.value === undefined) {
                return;
            }
            const selectedCompany = this.signupSignupSearchResult[event.detail.value];
            this.signupFormData.orgName = selectedCompany.navn;
            this.signupFormData.orgNo = selectedCompany.organisasjonsnummer;
            this.signupFormData.streetAddress =
                selectedCompany.forretningsadresse.adresse.join(', ');
            this.signupFormData.postalNo =
                selectedCompany.forretningsadresse.postnummer;
            this.signupFormData.postalAddress =
                selectedCompany.forretningsadresse.poststed;
            this.requestUpdate('signupFormData');
            this.signupSignupSearchResult = [];
        }}"
                                    >
                                        ${this.signupSignupSearchResult.map((result, resultIndex) => html ` <vaadin-item value="${resultIndex}">
                                                    ${result.navn} (Org. nr.
                                                    ${result.organisasjonsnummer})</vaadin-item
                                                >`)}
                                    </vaadin-list-box>
                                </div>
                                <div class="w-full lg:w-1/3 lg:ml-4">
                                    <vaadin-number-field
                                        class="validate"
                                        label="Organisasjonsnummer"
                                        placeholder=""
                                        value="${this.signupFormData.orgNo}"
                                        @value-changed="${(event) => {
            this.signupFormData.orgNo = event.target.value;
            this.requestUpdate('signupFormData');
        }}"
                                        autocomplete="cc-csc"
                                        required
                                        error-message="Organisasjonsnummer må fylles ut"
                                    ></vaadin-number-field>
                                </div>
                            </div>

                            <vaadin-text-field
                                class="validate"
                                label="Postadresse"
                                autocomplete="work"
                                value="${this.signupFormData.streetAddress}"
                                @value-changed="${(event) => {
            this.signupFormData.streetAddress = event.target.value;
            this.requestUpdate('signupFormData');
        }}"
                                required
                                error-message="Adresse må fylles ut"
                            ></vaadin-text-field>

                            <div class="flex flex-col lg:flex-row">
                                <div class="w-full lg:w-32 lg:mr-4">
                                    <vaadin-number-field
                                        class="validate"
                                        label="Postnummer"
                                        autocomplete="work"
                                        value="${this.signupFormData.postalNo}"
                                        @value-changed="${(event) => {
            const value = event.target.value;
            this.signupFormData.postalNo = value;
            if (!value) {
                return;
            }
            if (this.typingTimer) {
                clearTimeout(this.typingTimer);
            }
            this.typingTimer = window.setTimeout(() => {
                this._lookupPostalNumber(value).then((value) => {
                    this.signupFormData.postalAddress = value;
                    this.requestUpdate('signupFormData');
                });
            }, 300);
        }}"
                                        required
                                        error-message="Postnummer må fylles ut"
                                    ></vaadin-number-field>
                                </div>
                                <div class="w-full mb-8">
                                    <vaadin-text-field
                                        class="validate"
                                        label="Poststed"
                                        autocomplete="work"
                                        value="${this.signupFormData.postalAddress}"
                                        @value-changed="${(event) => {
            this.signupFormData.postalAddress = event.target.value;
            this.requestUpdate('signupFormData');
        }}"
                                        required
                                        readonly
                                        error-message="Poststed må fylles ut"
                                    ></vaadin-text-field>
                                </div>
                            </div>
                            <div class="flex flex-row justify-content">
                                <vaadin-checkbox
                                    label="Fakturaadresse er det samme som postadresse."
                                    ?checked="${this.invoiceSameAsAddress}"
                                    @checked-changed="${(event) => {
            this.invoiceSameAsAddress = event.detail.value;
            if (this.invoiceSameAsAddress) {
                this.invoiceAddress = '';
                this.invoicePostalCode = '';
                this.invoicePostalAddress = '';
            }
        }}"
                                >
                                </vaadin-checkbox>
                            </div>
                            <div class="${this.invoiceSameAsAddress ? 'hidden' : ''}">
                                <vaadin-text-field
                                    class="validate"
                                    label="Fakturaadresse"
                                    autocomplete="work"
                                    value="${this.invoiceAddress}"
                                    @value-changed="${(event) => {
            this.invoiceAddress = event.target.value;
        }}"
                                    ?required="${!this.invoiceSameAsAddress}"
                                    ?disabled="${this.invoiceSameAsAddress}"
                                    error-message="Fakturaadresse må fylles ut"
                                ></vaadin-text-field>

                                <div class="flex flex-col lg:flex-row">
                                    <div class="w-full lg:w-32 lg:mr-4">
                                        <vaadin-number-field
                                            class="validate"
                                            label="Postnummer"
                                            autocomplete="work"
                                            value="${this.invoicePostalCode}"
                                            @value-changed="${(event) => {
            const value = event.target.value;
            this.invoicePostalCode = value;
            if (!value) {
                return;
            }
            if (this.typingTimer) {
                clearTimeout(this.typingTimer);
            }
            this.typingTimer = window.setTimeout(() => {
                this._lookupPostalNumber(value).then((value) => {
                    this.invoicePostalAddress = value;
                });
            }, 300);
        }}"
                                            ?required="${!this.invoiceSameAsAddress}"
                                            ?disabled="${this.invoiceSameAsAddress}"
                                            error-message="Postnummer må fylles ut"
                                        ></vaadin-number-field>
                                    </div>
                                    <div class="w-full mb-8">
                                        <vaadin-text-field
                                            class="validate"
                                            label="Poststed"
                                            autocomplete="work"
                                            value="${this.invoicePostalAddress}"
                                            @value-changed="${(event) => {
            this.invoicePostalAddress = event.target.value;
        }}"
                                            ?required="${!this.invoiceSameAsAddress}"
                                            ?disabled="${this.invoiceSameAsAddress}"
                                            readonly
                                            error-message="Poststed må fylles ut"
                                        ></vaadin-text-field>
                                    </div>
                                </div>
                            </div>

                            <div class="flex flex-col lg:flex-row">
                                <div class="w-full lg:w-2/3 lg:mr-4">
                                    <vaadin-text-field
                                        class="validate"
                                        label="Fornavn"
                                        autocomplete="cc-given-name"
                                        @value-changed="${(event) => {
            this.signupFormData.firstName = event.target.value;
            this.requestUpdate('signupFormData');
        }}"
                                        required
                                        error-message="Fornavn må fylles ut"
                                    ></vaadin-text-field>
                                </div>
                                <div class="w-full">
                                    <vaadin-text-field
                                        class="validate"
                                        label="Etternavn"
                                        autocomplete="cc-family-name"
                                        @value-changed="${(event) => {
            this.signupFormData.lastName = event.target.value;
            this.requestUpdate('signupFormData');
        }}"
                                        required
                                        error-message="Etternavn må fylles ut"
                                    ></vaadin-text-field>
                                </div>
                            </div>

                            <div class="flex flex-col lg:flex-row">
                                <div class="w-full lg:w-2/3 lg:mr-4">
                                    <vaadin-number-field
                                        class="validate"
                                        label="Mobilnummer"
                                        autocomplete="tel"
                                        @value-changed="${(event) => {
            this.signupFormData.phone = event.target.value;
            this.requestUpdate('signupFormData');
        }}"
                                        required
                                        error-message="Telefonnummer må fylles ut"
                                    ></vaadin-number-field>
                                </div>
                                <div class="w-full">
                                    <vaadin-email-field
                                        ?invalid="${this.emailExists}"
                                        class="validate"
                                        label="E-postadresse"
                                        autocomplete="email"
                                        @value-changed="${(event) => {
            this.signupFormData.email = event.target.value;
            this.requestUpdate('signupFormData');
            this.emailExists = false;
        }}"
                                        required
                                        error-message="${this.emailExists
            ? 'E-postadresse eksisterer allerede'
            : 'E-postadresse må fylles ut'}"
                                    ></vaadin-email-field>
                                </div>
                            </div>

                            <div class="flex flex-col sm:flex-row">
                                <div class="w-full sm:w-2/3 sm:mr-4 pt-4">
                                    <label class="text-sm block"> Fakturatype </label>
                                    <div class="relative">
                                        <svg
                                            class="absolute right-0 mr-2 mt-2 fill-current h-6 w-6 pointer-events-none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                        >
                                            <path
                                                d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                                            />
                                        </svg>

                                        <select
                                            placeholder="type"
                                            class="block appearance-none w-full px-2 rounded"
                                            @change="${(event) => {
            this.signupFormData.ehfInvoice =
                event.target.value === 'ehf';
            if (this.signupFormData.ehfInvoice) {
                this.invoiceEmail = '';
            }
            this.requestUpdate('signupFormData');
        }}"
                                        >
                                            <option value="email">E-post</option>
                                            <option value="ehf">EHF</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="w-full">
                                    <vaadin-email-field
                                        class="validate"
                                        label="E-postfaktura"
                                        autocomplete="email"
                                        value="${this.invoiceEmail}"
                                        @value-changed="${(event) => {
            this.invoiceEmail = event.target.value;
        }}"
                                        ?disabled="${this.signupFormData.ehfInvoice}"
                                        ?required="${!this.signupFormData.ehfInvoice}"
                                        error-message="E-postfaktura må fylles ut"
                                    ></vaadin-email-field>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="text-center">
                        Ved å trykke send inn godtar du våre
                        <a
                            href="documents/kjopsbetingelser.pdf"
                            target="_blank"
                            class="no-underline text-cerum-orange-darker font-bold"
                        >
                            kjøpsvilkår
                        </a>
                    </div>
                    <div class="w-full justify-center max-w-2xl ml-auto mr-auto p-8">
                        <div class="mb-2">
                            <button
                                @click="${this._postSignupData}"
                                type="submit"
                                class="w-full bg-green-dark hover:bg-green-darker text-white font-bold py-4 px-4 rounded shadow-lg hover:shadow-md uppercase text-xl tracking-wide"
                            >
                                Send inn
                            </button>
                        </div>
                    </div>
                </div>
            </div>`;
    }
    _lookupPostalNumber(number) {
        return __awaiter(this, void 0, void 0, function* () {
            const lookupResult = yield SelfservicesignupAPI.postV1SignupLookup({ postal_code: parseInt(number) });
            if (isSuccessHTTP(lookupResult)) {
                return lookupResult.municipality || '';
            }
            else if (isErrorHTTP(lookupResult)) {
                console.error(lookupResult);
            }
            return '';
        });
    }
    _postSignupData() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this._validateFields()) {
                return;
            }
            this.emailExists = false;
            const signupResult = yield SelfservicesignupAPI.postV1Signup(this.signupFormData);
            if (isSuccessHTTP(signupResult)) {
                console.log('Alt gikk bra, sender videre.');
                Router.go('/success');
            }
            else if (isErrorHTTP(signupResult)) {
                if (signupResult.error.http_status === 409) {
                    this.emailExists = true;
                }
                else {
                    const errorResult = yield SystemErrorAPI.postSystemError({
                        context: {
                            method: 'POST',
                            endpoint: '/v1/signup',
                            httpStatus: signupResult.error.http_status,
                            error: signupResult.error.message,
                            response: '',
                        },
                        message: signupResult.error.type,
                        origin: 'Web',
                    });
                    if (isSuccessHTTP(errorResult)) {
                        Router.go('/error?id=' + errorResult.id);
                    }
                    else
                        isErrorHTTP(errorResult);
                    {
                        Router.go('/error?id=UNKNOWN_ERROR');
                    }
                }
            }
        });
    }
    createRenderRoot() {
        return this;
    }
};
SignupPageForm._deps = [TextField, ListBox, NumberField, Checkbox, EmailField, Item];
__decorate([
    state(),
    __metadata("design:type", Object)
], SignupPageForm.prototype, "invoiceSameAsAddress", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], SignupPageForm.prototype, "invoiceAddress", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], SignupPageForm.prototype, "invoicePostalCode", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], SignupPageForm.prototype, "invoicePostalAddress", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], SignupPageForm.prototype, "invoiceEmail", void 0);
__decorate([
    state(),
    __metadata("design:type", Object)
], SignupPageForm.prototype, "signupFormData", void 0);
__decorate([
    state(),
    __metadata("design:type", Array)
], SignupPageForm.prototype, "signupSignupSearchResult", void 0);
__decorate([
    state(),
    __metadata("design:type", Boolean)
], SignupPageForm.prototype, "emailExists", void 0);
SignupPageForm = __decorate([
    customElement(SignupPageForm.is),
    __metadata("design:paramtypes", [])
], SignupPageForm);
export { SignupPageForm };
