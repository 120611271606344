/* tslint:disable: class-name */
/*
 * AUTOGENERATED FILE - DO NOT EDIT
 */
import { APIRestClient } from '../../../APIRestClient';
import { exPostalcode } from '../../../schemas/exPostalcode';
import { exProject } from '../../../schemas/exProject';
import { exSignupProducts } from '../../../schemas/exSignupProducts';
export class SelfservicesignupAPI extends APIRestClient {
    /**
     * Hent standalone-produkter
     * Implementert hovedsakelig i *ewRestResourceSignupProducts*::*read*
     */
    static getV1SignupProducts() {
        return this._get('/v1/signup/products', {}, null, null, exSignupProducts.classOf(exSignupProducts, new exSignupProducts()), ['https://api.cerum.no/cerum/signup', 'https://api.cerum.no/cerum/standalone'], 'json');
    }
    /**
     * Registrer ny bruker
     * Implementert hovedsakelig i *ewRestResourceSignup*::*create*
     * string $resource
     *  string $query_str
     *  array<string, string> $headers
     *  bpInputStream|null $data
     *  bpRestRequestJsonResponse
     *  RestException
     *  bpIllegalArgumentException
     *  bpIllegalStateException
     *  bpRestNotLoggedInException
     */
    static postV1Signup(__DATA) {
        return this._post('/v1/signup', {}, null, JSON.stringify(__DATA), APIRestClient.Native.string(), ['https://api.cerum.no/cerum/signup', 'https://api.cerum.no/cerum/standalone'], 'json');
    }
    /**
     * S�k i postnummer etter sted brukes for validering i signup
     * Implementert hovedsakelig i *ewRestResourceLookup*::*lookupAreaCode*
     */
    static postV1SignupLookup(__QUERY) {
        return this._post('/v1/signup/lookup', {}, __QUERY, null, exPostalcode.classOf(exPostalcode, new exPostalcode()), ['https://api.cerum.no/cerum/signup', 'https://api.cerum.no/cerum/standalone'], 'json');
    }
    /**
     * Create Signup project
     * Implementert hovedsakelig i *ewRestRequestSignupProject*::*create*
     */
    static postV1SignupProjects(__DATA) {
        return this._post('/v1/signup/projects', {}, null, JSON.stringify(__DATA), exProject.classOf(exProject, new exProject()), ['https://api.cerum.no/cerum/signup', 'https://api.cerum.no/cerum/standalone'], 'json');
    }
}
